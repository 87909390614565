import Vue from 'vue'
import {
    createRouter,
    createWebHistory,
    RouteRecordRaw,
    RouteLocationNormalized,
    RouteLocationRaw
  } from 'vue-router'
import AppHeader from '@/components/layout/AppHeader.vue'
import * as R from 'vue-router'
import AppHeaderSimple from '@/components/layout/AppHeaderSimple.vue'
import AppFooter from '@/components/layout/AppFooter.vue'
import store from '@/store'

const guardSecure: R.NavigationGuard = async (
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: (to?: RouteLocationRaw | false | ((vm: typeof Vue) => any) | void) => void
) => {
    store.state.showSignIn = false;
    store.state.showSecure = true;

    if (store.state.$auth.loading) {
        await store.state.$auth.loadingPromise();
    }

    // If loading has already finished, check our auth state using `fn()`
    if(!store.state.$auth.loading) {
        if (store.state.$auth.isAuthenticated) {
            return next();
        } else {
            return next({ name: 'Login', query: { targetUrl: to.fullPath } });
        }
    }
};

const guardLogin = async(to: RouteLocationNormalized, from: RouteLocationNormalized, next: R.NavigationGuardNext) => {
    if (store.state.$auth.loading) {
        await store.state.$auth.loadingPromise();
    }
    if (store.state.$auth.isAuthenticated) {
        next("/landing");
    } else {
        next();
    }
};

const guardPublic: R.NavigationGuard = (to: RouteLocationNormalized, from: RouteLocationNormalized, next: (to?: R.NavigationGuardNext | false | ((vm: typeof Vue) => any) | void) => void) => {
    store.state.showSignIn = true
    store.state.showSecure = false
    next()
}

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'Home',
        beforeEnter: guardPublic,
        components: {
            header: AppHeader,
            default: () => import('./views/public/Home.vue'),
            footer: AppFooter
        }
    },
    {
        path: '/login',
        beforeEnter: guardLogin,
        name: 'Login',
        components: {
            header: AppHeader,
            default: () => import('./views/public/Login.vue'),
            footer: AppFooter
        }
    },
    {
        path: '/landing',
        name: 'Landing',
        beforeEnter: guardSecure,
        components: {
            header: AppHeader,
            default: () => import('./views/secure/Landing.vue'),
            footer: AppFooter
        }
    },
    {
        path: '/logout',
        name: 'Logout',
        components: {
            header: AppHeaderSimple,
            default: () => import('./views/public/Logout.vue'),
            footer: AppFooter
        }
    },
    {
        path: '/users/:tabName',
        name: 'UsersTab',
        beforeEnter: guardSecure,
        components: {
            header: AppHeader,
            default: () => import('./views/secure/Users.vue'),
            footer: AppFooter
        }
    },
    {
        path: '/users/',
        name: 'Users',
        beforeEnter: guardSecure,
        components: {
            header: AppHeader,
            default: () => import('./views/secure/Users.vue'),
            footer: AppFooter
        }
    },
    {
        path: '/organisations/:tabName',
        name: 'OrganisationsTab',
        beforeEnter: guardSecure,
        components: {
            header: AppHeader,
            default: () => import('./views/secure/Organisations.vue'),
            footer: AppFooter
        }
    },
    {
        path: '/organisations/',
        name: 'Organisations',
        beforeEnter: guardSecure,
        components: {
            header: AppHeader,
            default: () => import('./views/secure/Organisations.vue'),
            footer: AppFooter
        }
    },
    {
        path: '/customers/:tabName/:customerId?',
        name: 'CustomersTab',
        beforeEnter: guardSecure,
        components: {
            header: AppHeader,
            default: () => import('./views/secure/Customers.vue'),
            footer: AppFooter
        }
    },
    {
        path: '/customers/',
        name: 'Customers',
        beforeEnter: guardSecure,
        components: {
            header: AppHeader,
            default: () => import('./views/secure/Customers.vue'),
            footer: AppFooter
        }
    },
    {
        path: '/industries/:tabName',
        name: 'MarketHoldersTab',
        beforeEnter: guardSecure,
        components: {
            header: AppHeader,
            default: () => import('./views/secure/MarketHolders.vue'),
            footer: AppFooter
        }
    },
    
    {
        path: '/industries/',
        name: 'MarketHolders',
        beforeEnter: guardSecure,
        components: {
            header: AppHeader,
            default: () => import('./views/secure/MarketHolders.vue'),
            footer: AppFooter
        }
    },
    {
        path: '/reports/',
        name: 'AdminReports',
        beforeEnter: guardSecure,
        components: {
            header: AppHeader,
            default: () => import('./views/secure/Reports.vue'),
            footer: AppFooter
        }
    },
    {
        path: '/roadmap/',
        name: 'AdminRoadmap',
        beforeEnter: guardSecure,
        components: {
            header: AppHeader,
            default: () => import('./views/secure/Roadmapping.vue'),
            footer: AppFooter
        }
    }

]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
  })

export default router;
