import CustomerService from '@/services/common/CustomerService';

const customerService = new CustomerService();

export const getAllCustomers = async({ commit }: {commit: Function}) => {
    commit('isFetching', true);
    const customers = await customerService.getAllCustomers();
    commit('allCustomers', customers);
    commit('isFetching', false);
}

export const createCustomer = async({ commit }: {commit: Function}, payload: any) => {
    await customerService.createCustomer(payload.customer);
}

export const updateCustomer = async({ commit }: { commit: Function }, payload: any) => {
    await customerService.updateCustomer(payload.customerId, payload.customer);
}

export const deleteCustomer = async({ commit }: {commit: Function}, payload: any) => {
    await customerService.deleteCustomer(payload);
}
