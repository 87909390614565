import * as Axios from 'axios';
import BaseService from './BaseService';
import store from '@/store';
import { IUser, IUserProfile } from '@/domain/common';

export default class UserService extends BaseService {
    public async getUserProfile(): Promise<any> {
        const apiConfig: Axios.AxiosRequestConfig = {
            method: 'get',
            url: `${store.state.apiBase}/pc/users/profile`,
            headers: { Authorization: `Bearer ${store.state.$auth.token}` }
        }

        const res: Axios.AxiosResponse = await this.makeAxiosCall(apiConfig)
        return res.data as IUserProfile;
    }

    public async getAllUsers(): Promise<IUser[]> {
        const apiConfig: Axios.AxiosRequestConfig = {
            method: 'get',
            url: `${store.state.apiBase}/pc/users/`,
            headers: { Authorization: `Bearer ${store.state.$auth.token}` }
        }

        const res: Axios.AxiosResponse = await this.makeAxiosCall(apiConfig)
        return res.data as IUser[];
    }

    public async getUser(userId: string): Promise<IUser> {
        const apiConfig: Axios.AxiosRequestConfig = {
            method: 'get',
            url: `${store.state.apiBase}/pc/users/${userId}`,
            headers: { Authorization: `Bearer ${store.state.$auth.token}` }
        }

        const res: Axios.AxiosResponse = await this.makeAxiosCall(apiConfig)
        return res.data as IUser;
    }

    public async getUsers(orgId: string): Promise<IUser[]> {
        const apiConfig: Axios.AxiosRequestConfig = {
            method: 'get',
            url: `${store.state.apiBase}/banking/organisations/${orgId}/users`,
            headers: { Authorization: `Bearer ${store.state.$auth.token}` }
        }

        const res: Axios.AxiosResponse = await this.makeAxiosCall(apiConfig)
        return res.data as IUser[];
    }
    public async getEnergyUsers(orgId: string): Promise<IUser[]> {
        const apiConfig: Axios.AxiosRequestConfig = {
            method: 'get',
            url: `${store.state.apiBase}/energy/organisations/${orgId}/users`,
            headers: { Authorization: `Bearer ${store.state.$auth.token}` }
        }

        const res: Axios.AxiosResponse = await this.makeAxiosCall(apiConfig)
        return res.data as IUser[];
    }

    public async createUser(user: IUser): Promise<IUser> {
        const apiConfig: Axios.AxiosRequestConfig = {
            method: 'post',
            url: `${store.state.apiBase}/pc/users`,
            data: {
                user
            },
            headers: { Authorization: `Bearer ${store.state.$auth.token}` }
        }

        const res: Axios.AxiosResponse = await this.makeAxiosCall(apiConfig);

        return res.data;
    }

    public async deactivateUser(userId: string): Promise<void> {
        const apiConfig: Axios.AxiosRequestConfig = {
            method: 'delete',
            url: `${store.state.apiBase}/pc/users/${userId}`,
            headers: { Authorization: `Bearer ${store.state.$auth.token}` }
        }

        await this.makeAxiosCall(apiConfig);
    }

    public async saveUser(userId: string, user: IUser): Promise<void> {
        const apiConfig: Axios.AxiosRequestConfig = {
            method: 'put',
            url: `${store.state.apiBase}/pc/users/update/${userId}`,
            data: {
                user
            },
            headers: { Authorization: `Bearer ${store.state.$auth.token}` }
        }

        const res: Axios.AxiosResponse = await this.makeAxiosCall(apiConfig)
        return res.data;
    }
}
