import { IHolder, IRegisterHolder } from '@/domain/energy'

export const allHolders = (state: any, holders: IHolder[]) => {
    state.allHolders = holders;
}
export const allMarketHolders = (state: any, marketHolders: IRegisterHolder[]) => {
    state.allMarketHolders = marketHolders;
}
export const allOrganisationCurrentProducts = (state: any, currentProds: any[]) => {
    state.allOrganisationCurrentProducts = currentProds;
}

export const holderSelected = (state: any, holderId: string) => {
    state.holderSelected = holderId;
}

