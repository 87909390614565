import { FetchBase, fetchable } from './FetchBase'
import { IFetchable, IUserProfile } from './common'
import UserService from '@/services/common/UserService'

export class UserProfile extends FetchBase implements IFetchable<IUserProfile>, IUserProfile {
  public firstName: string = ''
  public lastName: string = ''
  public email: string = ''
  public organisationIds: string[] = []
  public organisationRoles: {
      [key: string]: {
          roles: {
              read?: boolean
              edit?: boolean
              approve?: boolean
              admin?: boolean
              support?: boolean
          }
      }
  } = {}

  private userService = new UserService();

  @fetchable
  public async fetch(options?: any) {
    const userProfile = await this.userService.getUserProfile();

    this.firstName = userProfile.firstName
    this.lastName = userProfile.lastName
    this.email = userProfile.email
    this.organisationRoles = userProfile.organisations
    this.organisationIds = Object.keys(userProfile.organisations)
  }
}
