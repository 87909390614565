

import RoadmapService from "@/services/common/roadmapService";
const roadmapservice = new RoadmapService();


export const getAllRoadmaps = async({ commit }: {commit: Function}) => {
    commit('isFetching', true);
    const roadmaps = await roadmapservice.getAllRoadmaps();
    commit('allRoadmaps', roadmaps);
    commit('isFetching', false);
}

export const createRoadmap = async({ commit }: {commit: Function}, payload: any) => {
    await roadmapservice.createRoadmap(payload);
}

export const updateRoadmap = async({ commit }: {commit: Function}, payload: any) => {
    await roadmapservice.updateRoadmap(payload.id, payload.roadmap);
}

export const deleteRoadmap = async({ commit }: {commit: Function}, payload: any) => {
    await roadmapservice.deleteRoadmap(payload);
}