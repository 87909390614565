import * as Axios from 'axios';
import BaseService from './BaseService';
import store from '@/store';

import { Goal, Roadmap } from '@/domain/common/roadmap'



export default class RoadmapService extends BaseService {
    public async getAllRoadmaps(): Promise<Roadmap> {
        const apiConfig: Axios.AxiosRequestConfig = {
            method: 'get',
            url: `${store.state.apiBase}/pc/roadmaps`,
            headers: { Authorization: `Bearer ${store.state.$auth.token}` }
        }

        const res: Axios.AxiosResponse = await this.makeAxiosCall(apiConfig)
        return res.data as Roadmap;
    }

    public async createRoadmap(roadmap: Roadmap): Promise<Roadmap> {
        const apiConfig: Axios.AxiosRequestConfig = {
            method: 'post',
            url: `${store.state.apiBase}/pc/roadmaps`,
            data: roadmap,
            headers: { Authorization: `Bearer ${store.state.$auth.token}` }
        }

        const res: Axios.AxiosResponse = await this.makeAxiosCall(apiConfig)
        return res.data as Roadmap;
    }

    public async updateRoadmap(roadmapId: string, roadmap: Roadmap): Promise<void> {
        const apiConfig: Axios.AxiosRequestConfig = {
            method: 'put',
            url: `${store.state.apiBase}/pc/roadmaps/${roadmapId}`,
            data: {roadmap},
            headers: { Authorization: `Bearer ${store.state.$auth.token}` }
        }

        const res: Axios.AxiosResponse = await this.makeAxiosCall(apiConfig)
        return res.data;
    }

    public async deleteRoadmap(roadmapId: string): Promise<void> {
        const apiConfig: Axios.AxiosRequestConfig = {
            method: 'delete',
            url: `${store.state.apiBase}/pc/roadmaps/${roadmapId}`,
            headers: { Authorization: `Bearer ${store.state.$auth.token}` }
        }

        const res: Axios.AxiosResponse = await this.makeAxiosCall(apiConfig)
        return res.data;
    }
}
