import * as Axios from 'axios';
import BaseService from '@/services/common/BaseService';
import store from '@/store';
import { IHolder } from '@/domain/banking';

export default class IndustryService extends BaseService {
    public async getAllHolders(): Promise<IHolder[]> {
        const apiConfig: Axios.AxiosRequestConfig = {
            method: 'get',
            url: `${store.state.apiBase}/banking/industry/holders`,
            headers: { Authorization: `Bearer ${store.state.$auth.token}` }
        }

        const res: Axios.AxiosResponse = await this.makeAxiosCall(apiConfig)
        return res.data as IHolder[];
    }
    public async getMarketHolders(): Promise<IHolder[]> {
        const apiConfig: Axios.AxiosRequestConfig = {
            method: 'get',
            url: `${store.state.apiBase}/banking/industry/marketHolders`,
            headers: { Authorization: `Bearer ${store.state.$auth.token}` }
        }
        const res: Axios.AxiosResponse = await this.makeAxiosCall(apiConfig)
        return res.data as IHolder[];
    }
    public async getholderProducts(productsUri: string): Promise<IHolder[]> {
        const apiConfig: Axios.AxiosRequestConfig = {
            method: 'post',
            url: `${store.state.apiBase}/banking/industry/holderProducts/`,
            data: { holderUri: productsUri},
            headers: { Authorization: `Bearer ${store.state.$auth.token}` }
        }
        const res: Axios.AxiosResponse = await this.makeAxiosCall(apiConfig)
        return res.data as IHolder[];
    }

    public async createHolder(holder: IHolder): Promise<any> {
        const apiConfig: Axios.AxiosRequestConfig = {
            method: 'post',
            url: `${store.state.apiBase}/banking/industry/holders`,
            data: {
                holder
            },
            headers: { Authorization: `Bearer ${store.state.$auth.token}` }
        }

        const res: Axios.AxiosResponse = await this.makeAxiosCall(apiConfig)
        return res.data as any;
    }

    public async updateHolder(holderId: string, holder: IHolder): Promise<void> {
        const apiConfig: Axios.AxiosRequestConfig = {
            method: 'put',
            url: `${store.state.apiBase}/banking/industry/update/${holderId}`,
            data: {
                holder
            },
            headers: { Authorization: `Bearer ${store.state.$auth.token}` }
        }

        const res: Axios.AxiosResponse = await this.makeAxiosCall(apiConfig)
        return res.data;
    }

    public async deleteHolder(holderId: string): Promise<void> {
        const apiConfig: Axios.AxiosRequestConfig = {
            method: 'delete',
            url: `${store.state.apiBase}/banking/industry/${holderId}`,
            headers: { Authorization: `Bearer ${store.state.$auth.token}` }
        }

        const res: Axios.AxiosResponse = await this.makeAxiosCall(apiConfig)
        return res.data;
    }
}
