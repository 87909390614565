import { Roadmap } from "@/domain/common/roadmap";
import roadmaps from ".";

export const isFetching = (state: any, isFetching: boolean) => {
    state.isFetching = isFetching;
}

export const allRoadmaps = (state: any, roadmaps: Roadmap[]) => {
    state.roadmaps = roadmaps;
}

