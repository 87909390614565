import * as Axios from 'axios';
import BaseService from '@/services/common/BaseService';
import store from '@/store';
//import { IHolder } from '@/domain/types';

export default class ReportService extends BaseService {

    public async getWeeklyChanges(): Promise<any|undefined> {
        const apiConfig: Axios.AxiosRequestConfig = {
            method: 'get',
            url: `${store.state.apiBase}/banking/reports/changes/${this.calcWeeklyStartDate()}`,
            headers: { Authorization: `Bearer ${store.state.$auth.token}` }
        }

        const res: Axios.AxiosResponse = await this.makeAxiosCall(apiConfig)
        return res.data as any;
    }

    private calcWeeklyStartDate(): string {
        let prevMonday = new Date();
        let offset = prevMonday.getDay() === 0 ? 13 : prevMonday.getDay() + 6;
        prevMonday.setDate(prevMonday.getDate() - offset);
        return `${prevMonday.getFullYear()}-${this.zeroPad(prevMonday.getMonth()+1)}-${this.zeroPad(prevMonday.getDate())}`;        
    }

    private zeroPad(val: number): string {
        if (val >= 0 && val < 10) return '0' + val;
        return val.toString()
    }
}
