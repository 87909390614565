import { IUser } from "@/domain/common";
import cloneDeep from "lodash.clonedeep";

export const isFetching = (state: any) => state.isFetching;

function checkRoles(roles: any) {
    if (!roles) return;
    if (!Object.prototype.hasOwnProperty.call(roles, 'read')) roles.read = false;
    if (!Object.prototype.hasOwnProperty.call(roles, 'edit')) roles.edit = false;
    if (!Object.prototype.hasOwnProperty.call(roles, 'approve')) roles.approve = false;
    if (!Object.prototype.hasOwnProperty.call(roles, 'admin')) roles.admin = false;
    if (!Object.prototype.hasOwnProperty.call(roles, 'support')) roles.support = false;
}

function sortRoles(roles: any): any {
    const orderedRoles: any = {};
    Object.keys(roles).sort().forEach((key: string) => {
        orderedRoles[key] = roles[key];
    });
    return orderedRoles;
}

export const usersWithRolesDeclared = (state: any) => {
    const usersCopy = cloneDeep(state.usersInOrg);
    const userOrgSelected = state.userOrgSelected;

    usersCopy.forEach((user: IUser) => {
        if (user.modules?.energyProducts) {
            if (!Object.prototype.hasOwnProperty.call(user.modules.energyProducts, userOrgSelected)) {
                user.modules.energyProducts[userOrgSelected] = { roles: {} };
            }
            Object.keys(user.modules.energyProducts).forEach((orgId: string) => {
                if(user.modules){

                const roles = user.modules.energyProducts[orgId].roles;
                checkRoles(roles);
                user.modules.energyProducts[orgId].roles = sortRoles(roles);
                }
            });
    }

        if (!Object.prototype.hasOwnProperty.call(user.organisations, userOrgSelected)) {
            user.organisations[userOrgSelected] = { roles: {} };
        }

        Object.keys(user.organisations).forEach((orgId: string) => {
            const roles = user.organisations[orgId].roles;
            checkRoles(roles);
            user.organisations[orgId].roles = sortRoles(roles);
        });
    });

    return usersCopy;
}

export const allUsers = (state: any) => state.allUsers;
