import ReportService from '@/services/banking/ReportService';

const reportService = new ReportService();

export const getWeeklyChanges = async({ commit }: {commit: Function}) => {
    commit('isFetching', true);
    let data: any | undefined;

    try {
        data = await reportService.getWeeklyChanges();
        commit('weeklyChanges', data);
    } catch (err) {
        let message = 'Unknown error';
        if (err instanceof Error) message = err.message
        commit('weeklyChanges', { error: message });
    }

    commit('isFetching', false);
}

