export const isFetching = (state: any) => {
    return state.isFetching;
}

export const allHolders = (state: any) => {
    return state.allHolders;
}

export const marketHolders = (state: any) => {
    return state.marketHolders;
}
export const organisationProducts = (state: any) => {
    return state.organisationProducts;
}
