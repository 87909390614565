import { IOrganisation, ILoadPendingOrg } from '@/domain/energy'

export const isFetching = (state: any, isFetching: boolean) => {
    state.isFetching = isFetching;
}

export const allOrganisations = (state: any, organisations: IOrganisation[]) => {
    state.allOrganisations = organisations;
}
export const allLoadDataNewOrgs = (state: any, loadOrgs: ILoadPendingOrg[]) => {
    state.allLoadDataNewOrgs = loadOrgs;
}

export const orgSelected = (state: any, orgId: string) => {
    state.orgSelected = orgId;
}
